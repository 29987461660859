/* Контейнер для позиционирования */
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

/* Стили для фона */
.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* Стили для гекона */
.gekon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 40%;
  user-select: none;
  -webkit-user-drag: none;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Плавный переход */
}

/* Класс для плавного исчезновения */
.fade-out {
  opacity: 0;
}

/* Класс для плавного появления */
.fade-in {
  opacity: 1;
}

/* Медиазапросы для мобильных устройств */
@media (max-width: 768px) {
  .gekon {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .gekon {
    width: 70%;
  }
}
