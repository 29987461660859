.Boost {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #020B07;
  overflow: hidden;
}

.container {
  width: 100%;
  height: calc(100% - 92px);
  padding: 95px 28px 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.relative {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-bottom: 40px;
}

.title {
  z-index: 4;
  display: flex;
  position: relative;
  text-shadow: 0px 4.333px 37.592px rgba(0, 0, 0, 0.50);
  font-size: 33.793px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -1.3px;
}

.gradient {
  z-index: 3;

  position: absolute;
  font-size: 33.793px;
  font-weight: 700;
  letter-spacing: -1.3px;
  text-align: center;

  background: linear-gradient(181deg, #FFF 49.92%, #0F8 239.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow {
  z-index: 2;
  text-align: center;

  position: absolute;
  top: 3px;
  font-size: 33.793px;
  font-weight: 700;
  letter-spacing: -1.3px;

  color: #247953;
}

.freeDaily {
  width: 100%;
  margin-top: 50px;
}

.boostsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.boosts {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.boostTitle {
  font-size: 17.075px;
  font-weight: 500;
  line-height: 22.879px;
  letter-spacing: -0.657px;
}

.balance {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
}

.coin {
  position: absolute;
  width: 39px;
  left: -50px;
}

.score {
  z-index: 4;

  position: relative;
  text-shadow: 0px 4.333px 37.592px rgba(0, 0, 0, 0.50);
  font-size: 32.5px;
  font-weight: 700;

  
}

.score_gradient {
  z-index: 3;

  position: absolute;
  font-size: 32.5px;
  font-weight: 700;

  background: linear-gradient(181deg, #FFF 49.92%, #0F8 239.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.score_shadow {
  z-index: 2;

  position: absolute;
  top: 5px;
  font-size: 32.5px;
  font-weight: 700;

  color: #247953;
}